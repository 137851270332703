.empty-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.main-container {
  height: 100%;
  overflow: auto;
}
.container-heading {
  height: 64px;
  padding: 1rem;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  display: flex;
  color: var(--primary-color);
  justify-content: space-between;
}
.container-heading span {
  display: flex;
  gap: 8px;
  align-items: center;
}
.agent-prompt,
.agent-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
}
.call-log-details {
  display: flex;
  flex-direction: column;
  min-width: 420px;
  width: 100%;
}
.call-details {
  display: flex;
  flex-direction: column;
  align-items: baseline !important;
  gap: 0px !important;
}
.call-details-type {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.sentiment{
  display: flex;
  gap: 8px;
  align-items: center;
}
.sentiment-text{
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}
.sentiment-text.positive {
  color: #03543F ;
}
.sentiment-text.negative {
  color: #E02424 ;
}
.sentiment-text.neutral {
  color: #52DC34 ;
}
.ongoing-call {
  border-radius: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #16A34A;
  padding: 2px 8px;
  background: #DCFCE7;
}
.ongoing-call mat-icon {
  width: 16px;
  height: 16px;
}
.w-120{
  width: 120px;
}


.dropdown-toggle {
  color: #54656F;
  box-shadow: none;
}
.dropdown-toggle::after {
  display: none;
} 
.kebab-dropdown-menu {
  background: var(--bg-color);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 2px 6px 2px rgb(0 0 0 / 15%);
  border-radius: 10px;
  padding: 10px 10px;
}
.kebab-dropdown-item {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #54656F;
  padding: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
}
.kebab-dropdown-item:hover {
  background-color: var(--hover-color);
}
.kebab-dropdown-item mat-icon ::ng-deep svg path {
  fill: #54656F;
}
.top-bar-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}
.top-bar-actions span mat-icon ::ng-deep svg path {
    fill: #1283DA;
}

/* Chat Window */
.hor-8px {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ver-4px {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.call-logs-list {
  height: calc(100% - 64px);
}
.call-log-agent {
  background: var(--icon-bg-color);
  display: flex;
  align-items: center;
  gap: 4px;
  height: 18px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: var(--primary-color);
  border-radius: 8px;
  padding-right: 4px;
}
.call-log-agent img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.call-log-agent span {
  max-width: 135px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.message-time {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-color);
  display: flex;
  gap: 8px;
}
.message-user profile-icon ::ng-deep div {
  width: 24px;
  height: 24px;
  font-size: 12px;
}
.chat-window {
  background: var(--bg-color);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1rem 24px;
  gap: 1rem;
}
.chat-input-container {
  background-color: transparent;
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  padding: 0 5%;
}
.chat-msg-element {
  max-width: 1084px;
  width: 100%;
}
.msg-date-time-label {
  width: max-content;
  margin: 4px auto;
  color: var(--grey-dark-grey, #54656F);
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
}
.messages {
  position: relative;
  display: flex;
  width: 100%;
  margin: 4px 0;
  align-items: flex-start;
  gap: 8px;
}
.messages .msg-wrap {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 300px;
  padding: 8px 8px;
  border-radius: 16px 0px 16px 16px;
  font: 14px "Poppins", sans-serif;
  font-weight: 400;
  color: #000;
  min-width: 300px;
  background: var(--hover-color);
  box-shadow: none;
}
.received-msg {
  justify-content: flex-start;
}
.received-msg .msg-wrap { 
  border-radius: 0px 16px 16px 16px;
  background: var(--hover-color);
  box-shadow: none;
}
.received-msg .msg-wrap:hover {
  background: var(--hover-color);
}
.sent-msg {
  justify-content: flex-end;
}
.sent-msg .msg-wrap:hover {
  background: #F3E8FF;
}
.incoming-container,
.outgoing-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.incoming-call-description,
.outgoing-call-description {
  display: flex;
  gap: 16px;
  align-items: center;
}
.incoming-call-description .icon,
.outgoing-call-description .icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.incoming-call-description .icon {
  background: var(--icon-bg-color);
}
.outgoing-container .icon {
  background: #E9D5FF;
}
.incoming-call-description .person-name,
.outgoing-call-description .person-name {
  color: var(--primary-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin: 0;
}
.incoming-call-description .person-number,
.outgoing-call-description .person-number {
  color: var(--grey-medium-grey, var(--secondary-color));
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.msg-time-tick {
  position: absolute;
  right: 10px;
  bottom: 4px;
  height: 18px;
}
.msg-time-tick .msg-time {
  color: var(--grey-medium-grey, var(--secondary-color));
  text-align: right;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.call-log-labels {
  display: flex;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-body {
  border-radius: 10px;
  background: var(--miscellaneous-note, #FFF5CC);
  display: flex;
  padding: 6px 10px;
  gap: 10px;
}
.notes-body i {
  color: var(--secondary-color);
  margin-top: 2px;
}
.notes-description {
  display: block;
  max-width: 400px;
  white-space: nowrap;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 20px;
}
.notes-description-time {
  color: var(--grey-medium-grey, var(--secondary-color));
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 10px;
  border-right: 1px solid #A2B0B8;
  margin-right: 10px;
}
.notes-description-message {
  color: var(--grey-primary-text, var(--primary-color));
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.notes-description-name {
  color: var(--grey-medium-grey, var(--secondary-color));
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #A2B0B8;
}
.notes-body-system {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  margin: 5px 0;
}
.notes-description-message-system {
  color: var(--grey-dark-grey, #54656F);
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.notes-description-time-system {
  color: var(--grey-medium-grey, var(--secondary-color));
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #A2B0B8;
}
.call-dropdown {
  opacity: 0;
}
.messages:hover .call-dropdown {
  opacity: 1;
}
.msg-wrap:hover + .status-dropdown {
  opacity: 1;
}
.call-log-action {
  border: 0.5px solid var(--Border-bd-1, var(--icon-bg-color));
  background: var(--bg-color);
  box-shadow: 0px 1px 2px -1px #0000001A;
  box-shadow: 0px 1px 3px 0px #0000001A;
  padding: 4px 8px;
  opacity: 0;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.messages:hover .call-log-action {
  opacity: 1;
}
.call-log-label-list {
  display: flex;
  gap: 8px;
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
}
.status-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  width: 24px;
  height: 24px;
}
.status-dropdown .dropdown-menu {
  padding: 0;
  background: none;
  border: none;
  min-width: none;
}
.status-dropdown .dropdown-toggle:hover {
  opacity: 1;
}
.status-dropdown .dropdown-toggle {
  background: none;
  padding: 0 10px 0 0;
  height: 24px;
  opacity: 0;
}
.status-dropdown .dropdown-toggle::after {
  display: none;
}
.status-dropdown .dropdown-menu {
  transform: translate(-260px, 24px) !important;
}
call-info {
  display: none;
}
.status-dropdown .dropdown-menu.show call-info {
  display: block;
}


.call-log-summary {
  border: 1px solid var(--Border-bd-1, var(--icon-bg-color));
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 320px;
  border-radius: 16px;
  padding: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--primary-color);
  position: relative;
  margin-top: 10px;
}
.call-log-summary .summary-heading {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-color);
}
.call-log-summary .left-line {
  position: absolute;
  border-left: 1px solid var(--Border-bd-1, var(--icon-bg-color));
  height: 32px;
  width: 10px;
  border-bottom: 1px solid var(--Border-bd-1, var(--icon-bg-color));
  border-radius: 0 0 0 4px;
  top: -20px;
  left: -10px;
}
.received-msg .call-log-summary {
  margin-left: 8px;
}
.sent-msg .call-log-summary {
  margin-right: 8px;
  transform: translateX(52px);
}
.call-log-summary .right-line {
  position: absolute;
  border-right: 1px solid var(--Border-bd-1, var(--icon-bg-color));
  height: 32px;
  width: 10px;
  border-bottom: 1px solid var(--Border-bd-1, var(--icon-bg-color));
  border-radius: 0 0 4px 0;
  top: -20px;
  right: -10px;
}
.call-disconnection-reason {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-color);
}