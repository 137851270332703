.empty-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.main-container {
  height: 100%;
  overflow: auto;
}
.container-heading {
  height: 64px;
  padding: 1rem;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  display: flex;
  color: var(--primary-color);
  justify-content: space-between;
}
.container-heading span {
  display: flex;
  gap: 8px;
  align-items: center;
}
.agent-prompt,
.agent-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
}
.call-log-details {
  display: flex;
  flex-direction: column;
  min-width: 420px;
  width: 100%;
}
.vertical-line {
  /* height: 100%; */
  width: 1px;
  background: var(--border-color);
  min-height: 154px;
}
.call-rating-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  gap: 4px;
}
.call-rating-details .ratings {
  display: flex;
  gap: 1rem;
}
.ratings mat-icon {
  cursor: pointer;
}
.card-fields {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.heading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
  display: flex;
  gap: 8px;
  align-items: center;
}
.subheading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.hor-8px {
  display: flex;
  align-items: center;
  gap: 8px;
}
.subheading img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.overview-heading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  margin-bottom: 8px;
}
.field-name {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.user-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background: var(--hover-color);
  border-radius: 50%;
}
.call-details {
  display: flex;
  flex-direction: column;
  align-items: baseline !important;
  gap: 0px !important;
}
.call-details-type {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.call-details-direction {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--secondary-color);
}
.hangup-reason {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
}
.call-log-timings {
  display: flex;
  min-height: 37px;
  align-items: flex-start;
  padding-left: 8px;
  gap: 8px;
}
.transcript-log {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 8px 0;
  display: flex;
  color: var(--primary-color);
}
.transcript-log ul {
  list-style-type: disc;
}
.chat-sinppet-with-count {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}

.empty-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sentiment{
  display: flex;
  gap: 8px;
  align-items: center;
}
.sentiment-text{
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}
.sentiment-text.positive {
  color: #03543F ;
}
.sentiment-text.negative {
  color: #E02424 ;
}
.sentiment-text.neutral {
  color: #52DC34 ;
}
.ongoing-call {
  border-radius: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #16A34A;
  padding: 2px 8px;
  background: #DCFCE7;
}
.ongoing-call mat-icon {
  width: 16px;
  height: 16px;
}
.w-120{
  width: 120px;
}

.agent-details-tab {
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
}
.detail-type-tab {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 3px solid var(--bg-color);
}
.detail-type-tab span {
  padding: 0 12px 10px;
  display: flex;
  align-items: center;
}
.detail-type-tab.active {
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
}

.call-status-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;    
  max-width: 600px;
}
.call-status-details .Complete {
  width: 8px;
  height: 8px;
  background: #057A55;
  border-radius: 50%;
}
.call-status-details .Incomplete {
  width: 8px;
  height: 8px;
  background: #C81E1E;
  border-radius: 50%;
}
.call-status-description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
}
.summary-heading {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--secondary-color);
}
.transcript-details {
  height: 18px;
  position: relative;
  background: var(--hover-color);
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 8px;
}
.transcript-details.user {
  padding-left: 0;
  padding-right: 20px;
}
.transcript-details .icon-grey {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background: var(--hover-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transcript-details.user .icon-grey {
  right: 0;
  left: auto;
}
.transcript-details.user .icon-grey img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.transcript-details mat-icon {
  width: 12px;
  height: 12px;
}
.transcript-details .name {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--primary-color);
  padding: 0 8px 0 4px;
}
.transcript-details.user .name {
  padding: 0 4px 0 8px;
}
.msg-body {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
}
.msg-content {
  padding: 4px 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  max-width: 80%;
  width: fit-content;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border-radius: 8px;
  background: var(--hover-color);
}
.msg-body.user {
  align-items: flex-start;
}
.green {
  background: #16A34A;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.red {
  background: #DC2626;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
/* .agent .msg-content {
  border-radius: 8px;
  background: var(--hover-color);
}
.user .msg-content {
  border-radius: 8px;
  background: var(--bg-color);
  border: 1px solid var(--Border-bd-1, var(--border-color));
  color: var(--primary-color);
} */
.agent-tab-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.transcript-lines {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  padding-right: 4px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.transcript-messages {
  display: flex;
  gap: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  align-items: center;
}
.activity-tab {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.activity-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.activity-item .icon {
  width: 44px;
  height: 44px;
  background: var(--hover-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity-item mat-icon {
  width: 20px;
  height: 20px;
}
.activity-item mat-icon ::ng-deep svg path {
  fill: var(--icon-sec-color);
}
.activity-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.activity-details .time {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--secondary-color);
}
.activity-details .type {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.activity-divider {
  height: 32px;
  display: flex;
  align-items: center;
  width: 44px;
  justify-content: center;
}
.activity-divider hr {
  margin: 0;
  height: 1px;
  width: 32px;
  rotate: 90deg;
  padding: 0;
}
.extracted-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 480px;
}
.extracted-info-heading {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
  cursor: pointer;
}
.extracted-info-list {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  gap: 4px;
  padding-left: 24px;
}
.comment-list {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.comment-empty-screen {
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.comment-empty-screen img {
  width: 192px;
  height: 192px;
}
.comment-item {
  display: flex;
  flex-direction: column;
  gap: 4px; 
}
.comment-added {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.retry-recording {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
}