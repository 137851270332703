
.widget-preview {
  max-width: 320px;
}
.widget-main-body {
  border: 1px solid var(--Border-bd-1, var(--border-color));
  width: 320px;
  border-radius: 8px;
  /* background: var(--hover-color); */
  background-color: var(--hover-color);
}
.widget-heading {
  display: flex;
  gap: 4px;
  padding: 1rem;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid var(--Border-bd-1, var(--border-color));
}
.widget-heading img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--Border-bd-1, var(--border-color));
  /* background: var(--hover-color); */
}
.widget-heading-details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  gap: 4px;
}

.widget-body {
  padding: 2rem;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  background-color: var(--hover-color);
}
.widget-body img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.widget-body-status {
  display: flex;
  gap: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: var(--secondary-color);
  align-items: center;
}
.widget-body-name {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.widget-body-status span {
  width: 8px;
  height: 8px;
  background-color: #0E9F6E;
  border-radius: 50%;
}
.widget-footer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--Border-bd-1, var(--border-color));
  padding: 1rem;
  gap: 8px;
  width: 100%;
}
.widget-footer button {
  width: 100%;
  height: 38px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  border-radius: 8px;
  gap: 4px;
}
.widget-footer button mat-icon {
  width: 18px;
}
.widget-footer span {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  display: flex;
  color: var(--secondary-color);
  gap: 4px;
  justify-content: center;
}
.widget-footer img {
  height: 18px;
  border-radius: 50%;
}
.widget-icon {
  display: flex;
  width: 100%;
  padding: 2px;
}
.widget-icon span {
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}
.widget-icon mat-icon {
  width: 28px;
}
