.tippy-content {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 0;
}
.tippy-arrow::before {
  border-color: #FFFFFF;
  display: none;
}
.commands-editor div[contenteditable="true"] {
  width: 100%;
  background: var(--bg-color);
  border: 1px solid var(--Border-bd-1, var(--border-color));
  border-radius: 10px;
  padding: 9px 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary-color);
  resize: none;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100%;
  max-height: 500px;
}

.commands-editor div[contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: var(--secondary-color);
  display: block;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: left;
}
.commands-input-dropdown {
  padding: 8px;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
  gap: 8px;
  width: 100%;
}
.commands-input-dropdown:hover {
  background: var(--hover-color);
}

.commands-editor .mention {
  background:#F3E8FF; 
  font-weight: 600; 
  color: #7E22CE;
  border-radius: 4px; 
  padding: 0 2px;
  font-weight: 600;
}

.tiptap.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  color: #aaa;
  position: absolute;
  pointer-events: none;
}